<template>
  <div class="exam-result">
    <Breadcrumb class="custom-breadcrumb">
      <BreadcrumbItem>考试</BreadcrumbItem>
      <BreadcrumbItem>考试详情</BreadcrumbItem>
      <BreadcrumbItem>{{ exam.name }}</BreadcrumbItem>
    </Breadcrumb>

    <div class="header">
      <Card shadow style="width: 300px">
        <div slot="title">
          <JYIcon href="#icon-fenxi"></JYIcon>
          <span>总体分析</span>
        </div>
        <div class="content">
          <div class="item">
            <span>最高分</span>
            <span class="primary">{{ statistics.maxScore }}</span>
          </div>
          <div class="item">
            <span>平均分</span>
            <span>{{ (statistics.avgScore || 0).toFixed(2) }}</span>
          </div>
          <div class="item">
            <span>分数集中区</span>
            <span>80~90</span>
          </div>
        </div>
      </Card>
      <Card shadow style="width: 300px;margin-left: 20px">
        <div slot="title">
          <JYIcon href="#icon-kaoshi"></JYIcon>
          <span>测试结果</span>
        </div>
        <div class="content">
          <div class="item">
            <span>应考人数</span>
            <span>{{ exam.examineeCount }}</span>
          </div>
          <div class="item">
            <span>实际提交人数</span>
            <span>{{ exam.submitCount }}</span>
          </div>
          <div class="item">
            <span>通过人数</span>
            <span style="color: #4ACACB;">{{ exam.passCount }}</span>
          </div>
        </div>
      </Card>
      <Card shadow style="width: 300px;margin-left: 20px">
        <div slot="title">
          <JYIcon href="#icon-top"></JYIcon>
          <span>TOP 3</span>
        </div>
        <div class="content">
          <div class="item" v-for="(user, index) in statistics.top3" :key="index">
            <span>{{ user.realName }}</span>
            <span :class="`top${index + 1}`">{{ user.score }}</span>
          </div>
        </div>
      </Card>
    </div>

    <div class="examinee-content">
      <div class="table-container">
        <Table :columns="columns" :data="examinees.data" class="flex-table" :loading="loading" @on-row-click="handleRowClick">
          <template slot-scope="{ row, index }" slot="name">
            <div class="nowrap" :title="row.realName">{{ row.realName }}</div>
          </template>
          <template slot-scope="{ row, index }" slot="score">
            <div class="nowrap">{{ row.score || '--' }}</div>
          </template>
          <template slot-scope="{ row, index }" slot="department">
            <div class="nowrap" :title="row.departmentName">
              {{ row.departmentName }}
            </div>
          </template>
          <template slot-scope="{ row, index }" slot="use-time">
            <div class="nowrap">{{ row.duration || '--' }}</div>
          </template>
          <template slot-scope="{ row, index }" slot="participate">
            <div class="nowrap">{{ row.submitTime ? '是' : '否' }}</div>
          </template>
          <template slot-scope="{ row, index }" slot="pass">
            <div class="nowrap">
              {{ row.examStatus === EXAMINEE_STATUS.PASS.value ? '是' : row.examStatus === EXAMINEE_STATUS.UNPASS.value ? '否' : '--' }}
            </div>
          </template>
          <template slot-scope="{ row, index }" slot="submit-time">
            <div class="nowrap">
              {{ row.submitTime | formatTime('yyyy-MM-dd hh:mm') }}
            </div>
          </template>
        </Table>
      </div>

      <div class="page">
        <div style="float: right;">
          <Page
            show-total
            show-sizer
            show-elevator
            :total="examinees.total"
            :current="pageNumber + 1"
            @on-change="changePage"
            @on-page-size-change="changePageSize"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import examApi from '@api/exam'
import { EXAMINEE_STATUS, getStatusByValue } from '@/util/examineeStatus'

export default {
  name: 'ExamResult',
  data: function() {
    return {
      exam: {},
      pageNumber: 0,
      pageSize: 10,
      examinees: {
        total: 0,
        data: []
      },
      statistics: {},
      columns: [
        {
          title: '姓名',
          slot: 'name',
          className: 'name-column',
          tooltip: true
        },
        {
          title: '得分',
          slot: 'score',
          className: 'score-column'
        },
        {
          title: '部门',
          slot: 'department',
          className: 'department-column'
        },
        {
          title: '用时/分钟',
          slot: 'use-time',
          className: 'use-time-column'
        },
        {
          title: '是否参加',
          slot: 'participate',
          className: 'participate-column'
        },
        {
          title: '是否通过',
          slot: 'pass',
          className: 'pass-column'
        },
        {
          title: '提交时间',
          slot: 'submit-time',
          className: 'submit-time-column'
        }
      ],
      loading: false
    }
  },
  created() {
    examApi
      .getExamDetail(this.examId)
      .then(res => {
        this.exam = res.res
      })
      .then(() => {
        this.getExamineeDetail()
        this.getExamStatistics()
      })
  },
  methods: {
    getExamineeDetail() {
      this.loading = true
      examApi
        .getExamineeDetail({
          examId: this.examId,
          examStatus: this.exam.status,
          pageNumber: this.pageNumber,
          pageSize: this.pageSize
        })
        .then(res => {
          this.examinees = res.res
        })
        .finally(() => {
          this.loading = false
        })
    },
    getExamStatistics() {
      examApi.getExamStatistics(this.examId).then(res => {
        this.statistics = res.res
      })
    },
    changePage(page) {
      this.pageNumber = page - 1
      this.getExamineeDetail()
    },
    changePageSize(size) {
      this.pageSize = size
      this.getExamineeDetail()
    },
    handleRowClick(row, index) {
      if (!row.submitTime) {
        this.$message.warning('考生没有作答')
        return
      }
      window.open(`/manage/exam/detail/user/${this.examId}/${row.userId}?username=${row.realName}`)
    }
  },
  computed: {
    examId() {
      return Number(this.$route.params.examId)
    },
    EXAMINEE_STATUS() {
      return EXAMINEE_STATUS
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/theme/variables.less';

.exam-result {
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  background-color: @layout-body-background;

  ::v-deep .header {
    display: flex;
    margin-top: 10px;
    flex: 0 0 auto;

    .ivu-card-head {
      text-align: left;
      background-color: #e4e9ef;
      padding: 10px 12px;

      svg {
        margin-right: 6px;
      }
    }

    .content {
      .item {
        padding: 2px 14px;
        display: flex;
        justify-content: space-between;

        .primary {
          color: @primary-color;
        }

        .top1 {
          color: #ff4200;
        }

        .top2 {
          color: #eb7248;
        }

        .top3 {
          color: #e48462;
        }
      }
    }
  }

  ::v-deep .examinee-content {
    flex: 1 1 auto;
    margin-top: 20px;

    .flex-table {
      .ivu-table-row {
        cursor: pointer;
      }

      .name-column {
        width: 15%;
      }

      .score-column {
        width: 10%;
      }

      .department-column {
        width: 15%;
      }

      .use-time-column {
        width: 15%;
      }

      .participate-column {
        width: 15%;
      }

      .pass-column {
        width: 15%;
      }

      .submit-time-column {
        width: 15%;
      }
    }
  }

  .page {
    flex: 0 0 auto;
    padding: 20px;
    font-size: 14px;
    display: flex;
    justify-content: flex-end;

    .page-options {
      float: left;
      height: 32px;
      line-height: 32px;
    }
  }
}
</style>
